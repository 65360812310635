.reportingDatePicker input[type="text"] {
    min-width: 200px;
    height: 55.97px;
    border-color: rgba(var(--palette-common-onBackgroundChannel) / 0.23);
    border-radius: var(--shape-borderRadius);
    border-style: solid;
    border-width: 1px;
}

.reportingDatePicker input[type="text"]:hover {
    border-color: #000;
    border-radius: var(--shape-borderRadius);
    border-style: solid;
    border-width: 1px;
}

.react-datepicker-popper {
    z-index: 10;
}
