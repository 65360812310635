.formBuilder-0-2-1 .formHead-0-2-2 {
    width: 100% !important;
}

.formBuilder-0-2-1 .card-container {
    width: 100% !important;
    margin: 0.5em auto !important;
}

.formBuilder-0-2-1 .formHead-0-2-2 div {
    width: 100% !important;
}