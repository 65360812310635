.changelog {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 70%;
}

.article {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.article h2 {
    margin: 0 0 10px;
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
}

.article p {
    margin: 0;
    font-size: 1em;
    color: #666;
}
